<script setup lang="ts">
import { getServerState } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
import { defineAsyncComponent, computed } from 'vue'
import type { FormattedFooterContent } from '@/format-content'

// Components
const ZgSection = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgSection))
const content: FormattedFooterContent = getServerState('footer-content')!

const text = computed(() => content?.partOfZmarta?.title?.text)
</script>

<template>
  <zg-section background="brand-100">
    <div class="footer-part-of-zmarta">
      <img :src="content.logo?.src" :alt="content?.logo?.alt">

      <span v-if="text" class="separator">
        -
      </span>

      <span v-if="text">
        {{ text }}
      </span>
    </div>
  </zg-section>
</template>

<style lang="scss" scoped>
  .footer-part-of-zmarta {
    @include type-body-s;

    display: flex;
    align-items: flex-end;
    justify-content: center;
    color: #fff;
    margin: 0;

    img {
      width: rem(150px);
      fill: #fff;

      @include mq(medium) {
        width: rem(200px);
      }
    }

    span {
      display: none;

      @include mq(medium) {
        display: inline;
      }
    }

    span.separator {
      margin: 0 rem(16px);
    }
  }
</style>
